import React, { useState } from "react";
import haveNiceDay from "./have_nice_day.jpg";
import { getFunctions, httpsCallable } from "firebase/functions";

const DEFAULT_URL = "https://vod.tvp.pl/seriale,18/m-jak-milosc-odcinki,274703/odcinek-1739,S18E1739,735266";

type Item = {
  audioLang: string;
  audioType: string;
  quality: { bitrate: number; fps: any; height: any; width: any } | number;
  type: string;
  url: string;
  protection: any;
  video_360: boolean;
};
// TODO: Move this to backend (cloud functions).
const getBitrate = (item: Item): number => {
  if (typeof item.quality === "object" && item.quality !== null) {
      return item.quality.bitrate || -1;
  }
  if (typeof item.quality === "number") {
      return item.quality;
  }
  return -1;  // Default case
};

const REGION = "europe-central2";

const HeroComponent = () => {
  const [videoUrl, setVideoUrl] = useState(DEFAULT_URL);

  const [inProgress, setInProgress] = useState(false);

  const [extractedUrl, setExtractedUrl] = useState<string|null>(null);

  const handleInputChange = (e: any) => {
    setVideoUrl(e.target.value);
  };

  const handleCatchClick = async () => {
    setInProgress(true);
    console.log("Video URL:", videoUrl);

    const functions = getFunctions(undefined, REGION);
    const extractVodTvp = httpsCallable(functions, 'extractVodTvp');
    const response = await extractVodTvp({ url: videoUrl });

    // @ts-ignore
    const files = response.data["data"]["content"]["files"];
    console.log(files);

    // @ts-ignore
    const bestItem = files.reduce((prev, current) => {
     return getBitrate(prev) > getBitrate(current) ? prev : current;
    });

    console.log(bestItem);

    const finalUrl = bestItem["url"].replace(/\\/g, '');
    setExtractedUrl(finalUrl);
    setInProgress(false);
  };

  return (
    <div className="bg-white p-2">
      <div className="flex flex-col gap-4 items-center">
        <input
          type="url"
          value={videoUrl}
          onChange={handleInputChange}
          placeholder="Please enter video URL here..."
          className="p-6 border rounded-lg w-full text-xl"
        />
        {(inProgress === true) && <>
          <button
          disabled={true}
          className="bg-secondary-700 text-3xl font-semibold text-white py-4 px-6 rounded"
        >
          ...
        </button>
        </>}
        {(inProgress === false) && <>
        <button
          onClick={handleCatchClick}
          className="bg-secondary-600 hover:bg-secondary-700 text-3xl font-semibold text-white py-4 px-6 rounded"
        >
          Catch!
        </button>
        </>}
      </div>
      <div>
        {extractedUrl && (<>
          <div className="flex flex-col mt-4">
            <span className="text-blue-500 hover:text-blue-800 hover:no-underline text-2xl underline font-bold">
              <a href={extractedUrl} target="_blank" rel="noreferrer">{extractedUrl}</a>
            </span>
            <div className="text-red-600">
              If above link is not valid or you are not able to connect to it, please use <span className="font-bold">Catch!</span> button again.
            </div>
            <div className="mt-6">
              <img src={haveNiceDay} alt="Miłego dnia!" className="max-w-[512px]" />
            </div>
          </div>
        </>)}
      </div>
    </div>
  );
};

export default HeroComponent;
