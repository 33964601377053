import React from "react";
import logo from "./logo.svg";
import HeroComponent from "./Hero";

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <header className="p-1 bg-primary-700">
        <div className="container mx-auto h-20 flex">
          <a href="/">
            <img
              src={logo}
              alt="Cinecatch Logo"
              className="h-full w-auto inline-block p-3"
            />
          </a>
        </div>
      </header>

      <main className="container mx-auto my-10 p-4 grow">
        <HeroComponent />
      </main>

      <footer className="p-1">
        <div className="container mx-auto">
          <div className="text-center border-gray-300 border-t w-4/6 mx-auto">
            <p className="text-gray-400 my-5 text-sm">
              &copy; {new Date().getFullYear()} CineCatch. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
